import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

//PROMOTION_BANNER replace this
//PromotionBanner replace this
//promotionBanner replace this

export enum Actions {
  FETCH_PROMOTION_BANNER = `PromotionBannerModule/fetchPromotionBanners`,
  CREATE_PROMOTION_BANNER = `PromotionBannerModule/createPromotionBanner`,
  UPDATE_PROMOTION_BANNER = `PromotionBannerModule/updatePromotionBanner`,
  UPDATE_PROMOTION_BANNER_STATUS = `PromotionBannerModule/updatePromotionBannerStatus`,
  GET_PROMOTION_BANNER_BY_ID = `PromotionBannerModule/getPromotionBannerById`,
}

export enum Getters {
  IS_LOADING = `PromotionBannerModule/isLoading`,
  PROMOTION_BANNERS = `PromotionBannerModule/getPromotionBanners`,
  TOTAL = `PromotionBannerModule/getTotal`,
}

enum Mutations {
  SET_PROMOTION_BANNER = 'setPromotionBanner',
  LOADING = 'setLoading',
  LOADED = 'setLoaded',
}

export enum BannerTypes {
  PROMOTION = 'promotion',
  NEWS = 'news',
}

export interface PromotionBanner {
  id?: string | null;
  title: string | null;
  description?: string | null;
  image: string | null;
  start: string | null;
  end: string | null;
  isActive: boolean;
  promotion?: string;
  type?: BannerTypes;
}

export type PromotionBannerPayload = PromotionBanner;

export interface PromotionBannerInfo {
  promotionBanners: PromotionBanner[];
  isLoading: boolean;
  total: number;
  rowsPerPage: number;
  currentPage: number;
}

@Module
export default class PromotionBannerModule
  extends VuexModule
  implements PromotionBannerInfo
{
  promotionBanners = [] as PromotionBanner[];
  loading = false;
  total = 0;
  rowsPerPage = 100;
  currentPage = 1;

  get isLoading(): boolean {
    return this.loading;
  }

  get getPromotionBanners(): PromotionBanner[] {
    return this.promotionBanners;
  }

  get getTotal(): number {
    return this.total;
  }

  @Mutation
  [Mutations.LOADING]() {
    this.loading = true;
  }

  @Mutation
  [Mutations.LOADED]() {
    this.loading = false;
  }

  @Mutation
  [Mutations.SET_PROMOTION_BANNER](payload: any) {
    const { datas, total } = payload;
    this.total = total;
    this.promotionBanners = datas;
    this.loading = false;
  }

  @Action
  async [Actions.FETCH_PROMOTION_BANNER.split('/')[1]]() {
    const condition = {
      page: `${this.currentPage}`,
      pageSize: `${this.rowsPerPage}`,
    };

    this.context.commit(Mutations.LOADING);
    try {
      const { data } = await ApiService.get(
        `promotion-banner?${new URLSearchParams(condition)}`
      );
      this.context.commit(Mutations.SET_PROMOTION_BANNER, data);
      return data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }

  @Action
  async [Actions.GET_PROMOTION_BANNER_BY_ID.split('/')[1]](
    id = 0
  ): Promise<PromotionBanner> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.get(`promotion-banner/${id}`);
    this.context.commit(Mutations.LOADED);
    return data.data;
  }

  @Action
  async [Actions.CREATE_PROMOTION_BANNER.split('/')[1]](
    payload: PromotionBannerPayload
  ) {
    const body = { ...payload };

    try {
      const { data } = await ApiService.post('promotion-banner', {
        ...body,
        type: BannerTypes.PROMOTION,
      });
      return data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }

  @Action
  async [Actions.UPDATE_PROMOTION_BANNER.split('/')[1]]({
    id,
    payload,
  }: {
    id: string;
    payload: PromotionBannerPayload;
  }) {
    try {
      const { data } = await ApiService.patch(
        `promotion-banner/${id}`,
        payload
      );
      console.log({ data });
      return data;
    } catch ({ response }) {
      return await Promise.reject(response);
    }
  }

  @Action
  async [Actions.UPDATE_PROMOTION_BANNER_STATUS.split('/')[1]]({
    id,
    isActive,
  }: {
    id: string;
    isActive: boolean;
  }): Promise<PromotionBanner> {
    this.context.commit(Mutations.LOADING);
    const { data } = await ApiService.patch(`promotion-banner/${id}`, {
      isActive,
    });
    this.context.commit(Mutations.LOADED);
    return data.data;
  }
}
