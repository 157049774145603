import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '@/store';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import JwtService from '@/core/services/JwtService';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.withCredentials = true;
    ApiService.vueInstance.axios.interceptors.request.use((config) => {
      if (config.headers?.noAuth) {
        delete config.headers.bypassAuth;
        delete config.headers.Authorization;
      } else {
        const { accessToken } = JwtService.getTokens();
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });
    ApiService.vueInstance.axios.interceptors.response.use(
      async (response) => {
        return response;
      },
      (err) => {
        if (!store.getters.isUserAuthenticated) {
          return Promise.reject(err);
        }

        if (err.response.status === 401 || err.response.status !== 888) {
          const { refreshToken } = JwtService.getTokens();

          return this.post('auth/refresh-token', {
            refreshToken,
          } as AxiosRequestConfig)
            .then(({ data }) => {
              store.commit(Mutations.SET_AUTH, data);
              const originalRequest: AxiosRequestConfig = err.config;
              originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
              return this.vueInstance.axios.request(originalRequest);
            })
            .catch((error) => {
              if (
                error.response.status === 401 ||
                error.response.status === 400
              ) {
                return store.dispatch(Actions.LOGOUT);
              }
            });
        }
        //  else if ([500, 404].includes(err.response.status)) {
        //   return store.commit(Mutations.SET_ERROR, err.response.status);
        // }

        return Promise.reject(err);
      }
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: Record<string, any>
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: Record<string, any>
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the PATCH HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: Record<string, any>
   * @returns Promise<AxiosResponse>
   */
  public static patch(
    resource: string,
    params: Record<string, any>
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static all(resource: AxiosResponse[]): Promise<AxiosResponse[]> {
    return ApiService.vueInstance.axios.all(resource);
  }
}

export default ApiService;
